.TestIt {
    position: relative;
    display : block;
    padding-top: .5em;
    width: 26%;
    height: 5em;
    margin : .3em;
    background-color:darkseagreen;
  }

.ClassDone {
  position: relative;
  display : block;
  padding-top: .5em;
  width: 26%;
  height: 5em;
  margin : .3em;
  background-color:maroon;
  color:silver;
}

.ParticipantList {
  position: relative;
  display : block;
  padding-top: .5em;
  width: 15em;
  height: 4em;
  margin : .3em;
  background-color: #014017;
  color : black;
}
 
.ParticipantDone {
  background-color:maroon;
  color : silver;
}

.emphasis-text {
    font-weight: bold;
}  

html{
    background-color:  darkgreen;
    background-image: url("../../images/amy-shamblen-x2lm26Rs2PM-unsplash.jpg");
    /* background-color: #c03636; */
  }

ul{
    list-style-type: none;
}
.rules{
    /* float: left; */
    padding: .25em;
    color:maroon;
  }

